<template>
  <div class="table">
    <div class="tablebox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="username" label="执法人"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="exceptionReason" label="异常原因">
        </el-table-column>
        <el-table-column prop="type" label="是否解决">
          <template slot-scope="scope">
            <div class="Yes" v-if="scope.row.type == 1">已解决</div>
            <div class="No" v-if="scope.row.type == 2">未通过</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="日期">
          <el-input v-model="formLabelAlign.date" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="执法人">
          <el-input v-model="formLabelAlign.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="异常原因">
          <el-input v-model="formLabelAlign.type" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="是否通过">
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysLawEnforcementExceptionRecord,
  DeletesysLawEnforcementExceptionRecord,
} from "@/request/api";
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        date: "",
        name: "",
        type: "",
      },
      checked: false,

      options: [
        {
          value: "已解决",
          label: "已解决",
        },
        {
          value: "未解决",
          label: "未解决",
        },
      ],
      value: "",
      total: 1,
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
    };
  },

  methods: {
    // --------------------------
    handleUpdate(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysLawEnforcementExceptionRecord({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // handleDelete(v) {
    //   console.log(v);
    // },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // 执法异常列表
    seelist() {
      GetsysLawEnforcementExceptionRecord({
        keyword: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .Yes {
    color: green;
  }
  .No {
    color: red;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>